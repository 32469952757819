import React from "react";
import { Placeholder } from "react-bootstrap";

const LoadingSkeleton = ({
  rows = 1,
  cols = 1,
  className = "",
  width = "100%",
  height = "20px",
  xs,
}) => {
  const getRandomValueInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  return (
    <div>
      {Array.from({ length: rows }).map((_, rowIndex) => (
        <div
          key={rowIndex}
          className="d-flex justify-content-between align-items-center mb-2"
        >
          {Array.from({ length: cols }).map((_, colIndex) => {
            const isSecond = colIndex === 1; // Target the second placeholder
            return (
              <div
                key={colIndex}
                style={{
                  direction: isSecond ? "rtl" : "ltr",
                  flexGrow: 1,
                  marginRight: isSecond ? 0 : "0.5rem",
                  marginLeft: isSecond ? "0.5rem" : 0,
                }}
              >
                <Placeholder
                  as="div"
                  animation="wave"
                  style={{ height: height, width: width }}
                  className="flex-grow-1"
                >
                  <Placeholder
                    className={`h-100 ${className}`}
                    xs={xs || getRandomValueInRange(10, 12)}
                  />
                </Placeholder>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default LoadingSkeleton;
