import React, { useState } from "react";
import { Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { useFetchFiles } from "../../hooks/useFetchDocuments";
import { ToastContainer, toast } from "react-toastify";
import { FaDownload } from "react-icons/fa";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import "react-toastify/dist/ReactToastify.css";

const FileDownloader = () => {
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const startingYear = 2024;
  const years = Array.from(
    { length: currentYear - startingYear + 1 },
    (_, i) => currentYear - i
  );
  const { data, isLoading, isError } = useFetchFiles(selectedYear);

  const handleFileSelection = (fileId) => {
    setSelectedFiles((prev) =>
      prev.includes(fileId)
        ? prev.filter((id) => id !== fileId)
        : [...prev, fileId]
    );
  };

  const downloadSingleFile = (file) => {
    if (file && file.content) {
      const binaryContent = atob(file.content);
      const blob = new Blob([binaryContent], {
        type:
          file.file_type.toLowerCase() === "pdf"
            ? "application/pdf"
            : "application/octet-stream",
      });
      saveAs(blob, `${file.file_name}.${file.file_type.toLowerCase()}`);
      toast.success(`${file.file_name} downloaded successfully!`);
    } else {
      toast.error("File content is not available.");
    }
  };

  const handleDownload = async () => {
    if (selectedFiles.length === 1) {
      const selectedFile = data.find((file) => file.id === selectedFiles[0]);
      if (selectedFile) downloadSingleFile(selectedFile);
    } else if (selectedFiles.length > 1) {
      const zip = new JSZip();
      selectedFiles.forEach((fileId) => {
        const file = data.find((item) => item.id === fileId);
        if (file && file.content) {
          const binaryContent = atob(file.content);
          zip.file(
            `${file.file_name}.${file.file_type.toLowerCase()}`,
            binaryContent,
            { binary: true }
          );
        }
      });
      const blob = await zip.generateAsync({ type: "blob" });
      saveAs(blob, `Selected_Files_${selectedYear}.zip`);
      toast.success("Files downloaded successfully as a ZIP folder!");
    } else {
      toast.warn("Please select at least one file to download.");
    }
  };

  return (
    <div className="file-downloader container mt-4">
      <Form.Group>
        <Form.Label>Select Year</Form.Label>
        <Form.Control
          as="select"
          value={selectedYear}
          onChange={(e) => setSelectedYear(Number(e.target.value))}
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <div className="file-list mt-4">
        {isLoading ? (
          <div className="text-center">
            <Spinner animation="border" />
          </div>
        ) : isError ? (
          <p className="text-danger">Error fetching files.</p>
        ) : data && data.length > 0 ? (
          <Form>
            <Row className="gy-3">
              {data.map((file) => (
                <Col xs={12} md={6} lg={4} key={file.id}>
                  <div className="d-flex align-items-center justify-content-between border p-2 rounded">
                    <Form.Check
                      type="checkbox"
                      checked={selectedFiles.includes(file.id)}
                      onChange={() => handleFileSelection(file.id)}
                    />
                    <div>
                      <p className="mb-0 fw-bold">{file.file_name}</p>
                      <p className="mb-0 text-muted">
                        {file.year} - {file.type}
                      </p>
                    </div>
                    <Button
                      variant="light"
                      onClick={() => downloadSingleFile(file)}
                    >
                      <FaDownload />
                    </Button>
                  </div>
                </Col>
              ))}
            </Row>
          </Form>
        ) : (
          <p>No files available for the selected year.</p>
        )}
      </div>
      <Button
        onClick={handleDownload}
        disabled={isLoading || !selectedFiles.length}
        className="mt-3"
      >
        {selectedFiles.length > 1
          ? "Download as ZIP"
          : "Download Selected File"}
      </Button>
    </div>
  );
};

export default FileDownloader;
