import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import PersonalInfo from "./Personalinfo";
import PasswordSecurity from "./Passwordsecurity";
import Navbar from "react-bootstrap/Navbar";
import Footer from "../../components/Footer";
import FileDownloader from "./DownloadTaxes";

function SettingDashboard() {
  const [activeTab, setActiveTab] = useState("personalinfo");

  const renderComponent = () => {
    switch (activeTab) {
      case "personalinfo":
        return <PersonalInfo />;
      case "passwordsecurity":
        return <PasswordSecurity />;
      case "taxes":
        return <FileDownloader />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="container-sm">
        <Navbar bg="white" className="mt-0 mb-2" style={{ zIndex: "0" }}>
          <Nav className="mt-0">
            <Nav.Link
              href="#personalinfo"
              active={activeTab === "personalinfo"}
              onClick={() => setActiveTab("personalinfo")}
              style={{
                color: activeTab === "personalinfo" ? "#2c3188" : "",
                borderBottom:
                  activeTab === "personalinfo" ? "2px solid #2c3188" : "",
              }}
            >
              <span className="h6">Personal Info</span>
            </Nav.Link>
            <Nav.Link
              href="#passwordsecurity"
              active={activeTab === "passwordsecurity"}
              onClick={() => setActiveTab("passwordsecurity")}
              style={{
                color: activeTab === "passwordsecurity" ? "#2c3188" : "",
                borderBottom:
                  activeTab === "passwordsecurity" ? "2px solid #2c3188" : "",
              }}
            >
              <span className="h6">Password & Security</span>
            </Nav.Link>
            <Nav.Link
              href="#wishlist"
              active={activeTab === "taxes"}
              onClick={() => setActiveTab("taxes")}
              style={{
                color: activeTab === "taxes" ? "#2c3188" : "",
                borderBottom: activeTab === "taxes" ? "2px solid #2c3188" : "",
              }}
            >
              <span className="h6">Taxes</span>
            </Nav.Link>
          </Nav>
        </Navbar>

        {renderComponent()}
      </div>
      <Footer />
    </>
  );
}

export default SettingDashboard;
