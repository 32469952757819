import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./RangeDatePicker.css";
import {
  useGetPropertyForReservation,
  useGetReservationSearch,
} from "../hooks/useGetWalletStats";
import ReservationPropertyCard from "./ReservationPropertyCard";
import {
  Alert,
  Button,
  Col,
  Form,
  InputGroup,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { FaCalendarAlt } from "react-icons/fa";
import LoadingSkeleton from "./LoadingSkeleton";

const RangeDatePicker = () => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [showAlert, setShowAlert] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const suggestionsRef = useRef(null); // Ref for the suggestion list

  const addTwoHoursAndFormat = (date) => {
    if (!date) return null;
    const newDate = new Date(date.getTime() + 2 * 60 * 60 * 1000);
    return newDate.toISOString().split("T")[0];
  };

  const formattedStartDate = addTwoHoursAndFormat(startDate);
  const formattedEndDate = addTwoHoursAndFormat(endDate);

  const { data, isLoading, error } = useGetPropertyForReservation(
    isSearching ? formattedStartDate : null,
    isSearching ? formattedEndDate : null,
    undefined,
    {
      key:
        selectedSuggestion?.type === "PROPERTY"
          ? selectedSuggestion?.key
          : selectedSuggestion?.value,
      type: selectedSuggestion?.type,
    }
  );

  const { data: suggestions } = useGetReservationSearch(searchTerm, {
    key:
      selectedSuggestion?.type === "PROPERTY"
        ? selectedSuggestion?.key
        : selectedSuggestion?.value,
    type: selectedSuggestion?.type,
  });

  const getAvailableDays = (data) => {
    const includedDays = [];

    if (data && Array.isArray(data.rate_calendar)) {
      data.rate_calendar.forEach((rate) => {
        if (!rate.is_before) {
          includedDays.push(rate.date);
        }
      });
    }

    return includedDays;
  };

  const availableDays = getAvailableDays(suggestions);

  useEffect(() => {
    if (!isLoading && data?.length === 0 && isSearching) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [data, isLoading, isSearching]);

  const handleSearch = () => {
    if (startDate && endDate) {
      setIsSearching(true);
      setShowSuggestions(false);
      setShowOffcanvas(false);
    }
  };

  const handleSelectSuggestion = (suggestion) => {
    setSelectedSuggestion(suggestion);
    setSearchTerm(suggestion.value);
    setShowSuggestions(false);
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
    setShowSuggestions(
      e.target.value.length > 0 && suggestions?.prepare_search?.length > 0
    );
  };

  // Close suggestions when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [suggestionsRef]);

  const handleInputClick = () => {
    setShowSuggestions(true);
  };

  const hasOptionalProperty = data?.some((property) => property.is_optional);

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  return (
    <div className="range-picker-wrapper">
      {!searchTerm && (
        <Button
          className="d-md-none w-100"
          size="sm"
          variant="primary"
          onClick={() => setShowOffcanvas(true)}
        >
          Where
        </Button>
      )}

      {searchTerm && (
        <Button
          onClick={() => setShowOffcanvas(true)}
          variant="outline-secondary"
          className="d-flex justify-content-between align-items-center w-100 gap-5 d-md-none mt-3"
        >
          <div className="d-flex flex-column w-100 text-start">
            <span className="fw-semibold fs-md">Where</span>
            <span className="fs-md fw-normal">{searchTerm}</span>
          </div>
        </Button>
      )}
      {startDate && endDate && (
        <Button
          onClick={() => setShowOffcanvas(true)}
          variant="outline-secondary"
          className="d-flex justify-content-between align-items-center w-100 gap-5 d-md-none mt-3"
        >
          <div className="d-flex flex-column align-items-start">
            <span className="fw-semibold fs-md">Check in</span>
            <span className="fs-md fw-normal">
              {startDate.toLocaleDateString("en-GB")}
            </span>
          </div>
          <div className="d-flex flex-column align-items-end">
            <span className="fw-semibold fs-md">Check out</span>
            <span className="fs-md fw-normal">
              {endDate.toLocaleDateString("en-GB")}
            </span>
          </div>
        </Button>
      )}

      {/* Offcanvas for mobile */}
      <Offcanvas
        show={showOffcanvas}
        onHide={() => setShowOffcanvas(false)}
        placement="bottom"
        style={{
          height: "auto",
        }}
      >
        {" "}
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Search</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form.Label>Where</Form.Label>
          <Form.Control
            type="text"
            size="sm"
            placeholder="Search for properties"
            value={searchTerm}
            onChange={handleSearchTermChange}
            onClick={handleInputClick} // Show suggestions on click
          />
          {showSuggestions && suggestions?.prepare_search?.length > 0 && (
            <div
              className="suggestion-dropdown mt-2"
              style={{
                position: "absolute",
                backgroundColor: "white",
                border: "1px solid #ccc",
                borderRadius: "4px",
                maxHeight: "140px",
                overflowY: "auto",
                zIndex: 1000, // Ensure it appears above other content
                width: "100%", // Match the input width
              }}
            >
              {suggestions.prepare_search.map((suggestion) => (
                <div
                  key={suggestion.key}
                  className="suggestion-item"
                  onClick={() => handleSelectSuggestion(suggestion)}
                  style={{
                    padding: "8px 12px",
                    cursor: "pointer",
                    transition: "background-color 0.2s ",
                    zIndex: 9999,
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f0f0f0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "white")
                  }
                >
                  {suggestion.value}
                </div>
              ))}
            </div>
          )}

          {/* Date Range Inputs */}
          <div className="range-picker-container my-3">
            <DatePicker
              selected={startDate}
              onChange={(update) => setDateRange(update)}
              startDate={startDate}
              endDate={endDate}
              minDate={new Date()}
              selectsRange
              placeholderText="Check-in — Check-out"
              className="range-picker-input"
              monthsShown={1}
              dateFormat="MMM d"
              includeDates={
                availableDays.length > 0 ? availableDays : undefined
              }
              customInput={
                <CustomInput
                  startDate={startDate}
                  endDate={endDate}
                  addTwoHoursAndFormat={addTwoHoursAndFormat}
                />
              }
            />
          </div>

          <Button
            variant="primary"
            className="w-100"
            onClick={handleSearch}
            disabled={!startDate || !endDate}
          >
            Search
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
      <Row className="d-none d-md-flex">
        <Col>
          <div style={{ position: "relative" }}>
            <Form.Label>Where</Form.Label>

            <Form.Control
              type="text"
              size="sm"
              placeholder="Search for properties"
              value={searchTerm}
              onChange={handleSearchTermChange}
              onClick={handleInputClick} // Show suggestions on click
            />

            {showSuggestions && suggestions?.prepare_search?.length > 0 && (
              <div
                className="suggestion-dropdown mt-2"
                style={{
                  position: "absolute",
                  backgroundColor: "white",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  maxHeight: "140px",
                  overflowY: "auto",
                  zIndex: 1000, // Ensure it appears above other content
                  width: "100%", // Match the input width
                }}
              >
                {suggestions.prepare_search.map((suggestion) => (
                  <div
                    key={suggestion.key}
                    className="suggestion-item"
                    onClick={() => handleSelectSuggestion(suggestion)}
                    style={{
                      padding: "8px 12px",
                      cursor: "pointer",
                      transition: "background-color 0.2s ",
                      zIndex: 9999,
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.backgroundColor = "#f0f0f0")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.backgroundColor = "white")
                    }
                  >
                    {suggestion.value}
                  </div>
                ))}
              </div>
            )}
          </div>
        </Col>

        <Col md={8}>
          <div className="range-picker-container d-flex align-items-center justify-content-start">
            <DatePicker
              selected={startDate}
              onChange={(update) => setDateRange(update)}
              startDate={startDate}
              endDate={endDate}
              minDate={new Date()}
              selectsRange
              placeholderText="Check-in — Check-out"
              className="range-picker-input"
              monthsShown={window.innerWidth <= 768 ? 1 : 2}
              dateFormat="MMM d"
              includeDates={
                availableDays.length > 0 ? availableDays : undefined
              }
              customInput={
                <CustomInput
                  startDate={startDate}
                  endDate={endDate}
                  addTwoHoursAndFormat={addTwoHoursAndFormat}
                />
              }
            />
            <Button
              style={{ marginTop: "30px" }}
              variant="primary"
              className="fw-semibold ms-2 w-25"
              size="sm"
              onClick={handleSearch}
              disabled={!startDate || !endDate}
            >
              Search
            </Button>
          </div>
        </Col>
      </Row>

      {showAlert && (
        <Alert variant="warning" className="mt-3">
          No properties available for the selected dates.
        </Alert>
      )}

      <Row className="g-6 mt-5">
        {hasOptionalProperty && isSearching && (
          <p className="text-center my-3 fs-lg" style={{ color: "#6c757d" }}>
            No available property for the requested days, but here are some
            other options:
          </p>
        )}

        {isLoading
          ? Array.from({ length: 6 }).map((_, index) => (
              <Col sm={12} md={6} lg={4} key={index}>
                <LoadingSkeleton
                  width="100%"
                  height="330px"
                  xs={12}
                  className="rounded-3"
                />
                <LoadingSkeleton width="70%" className="text-dark" />
                <LoadingSkeleton rows={2} cols={1} width="60%" />
                <LoadingSkeleton width="80%" className="text-black pt-2" />
              </Col>
            ))
          : data?.map((property) => (
              <Col key={property.property_id} sm={12} md={6} lg={4}>
                <ReservationPropertyCard
                  property={property}
                  startDate={formattedStartDate}
                  endDate={formattedEndDate}
                  isOptional={hasOptionalProperty}
                />
              </Col>
            ))}
      </Row>
    </div>
  );
};

const CustomInput = React.forwardRef(
  ({ value, onClick, startDate, endDate, addTwoHoursAndFormat }, ref) => (
    <Row className="gap-3 gap-md-0 custom-hover justify-content-center">
      <Col xs={12} md={6}>
        <Form.Group>
          <Form.Label>Start Date</Form.Label>
          <InputGroup
            size="sm"
            className="input-group-custom"
            onClick={onClick}
            ref={ref}
          >
            <Form.Control
              type="text"
              value={startDate ? addTwoHoursAndFormat(startDate) : ""}
              placeholder="Select Start Date"
              readOnly
              className="input-no-border"
            />
            <InputGroup.Text>
              <FaCalendarAlt className="text-gray-500" />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col xs={12} md={6}>
        <Form.Group>
          <Form.Label>End Date</Form.Label>
          <InputGroup
            size="sm"
            className="input-group-custom"
            onClick={onClick}
            ref={ref}
          >
            <Form.Control
              type="text"
              value={endDate ? addTwoHoursAndFormat(endDate) : ""}
              placeholder="Select End Date"
              readOnly
              className="input-no-border"
            />
            <InputGroup.Text className="">
              <FaCalendarAlt className="text-gray-500" />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
    </Row>
  )
);

export default RangeDatePicker;
