import React, { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import moment from "moment";

import { toast } from "react-toastify";
import {
  useGetBookRequestForUser,
  useUserRemoveBookRequest,
} from "../hooks/useGetBookedPropertiesForUser";
import { IoMdFunnel } from "react-icons/io";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useNavigate } from "react-router-dom";
import LoadingSkeleton from "./LoadingSkeleton";

const BookingTable = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [statusFilter, setStatusFilter] = useState("ALL");
  const queryClient = useQueryClient();

  const {
    data: bookHistoryData,
    isLoading: isHistoryLoading,
    refetch,
  } = useGetBookRequestForUser(statusFilter);
  const {
    data: filterData = [],
    refetch: refetchFilterData,
    isLoading: isPendingLoading,
  } = useGetBookRequestForUser("PENDING");
  const {
    data: openReservation = [],
    refetch: refetchOpenReservation,
    isLoading: isReservedLoading,
  } = useGetBookRequestForUser("RESERVED");

  // Mutation for removing booking
  const { mutate: removeBookingMutate, isLoading } = useMutation(
    useUserRemoveBookRequest,
    {
      onSuccess: () => {
        setShowModal(false);

        toast.success("Booking canceled successfully");
        refetch();
        refetchFilterData();
        refetchOpenReservation();
        queryClient.invalidateQueries(["vacation"]);

        // Optionally, refetch booking data here if needed.
      },
      onError: () => {
        toast.error("Failed to cancel the booking");
      },
    }
  );

  const handleClose = () => setShowModal(false);
  const handleShow = (booking) => {
    setSelectedBooking(booking);
    setShowModal(true);
  };

  const handleCancelBooking = () => {
    if (selectedBooking) {
      removeBookingMutate(selectedBooking.id); // Use mutate to trigger cancellation
    }
  };

  const [showFilters, setShowFilters] = useState(false);

  const clearFilters = () => {
    setStatusFilter();
  };

  useEffect(() => {
    refetch();
    refetchFilterData();
    refetchOpenReservation();
  }, []);

  const navigate = useNavigate();

  return (
    <Container>
      {isPendingLoading || isReservedLoading ? (
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-2">
          {Array.from({ length: 6 }).map((_, index) => (
            <div className="col" key={index}>
              <Card className="rounded">
                <LoadingSkeleton rows={1} height="190px" width="100%" xs={12} />

                <Card.Body>
                  <LoadingSkeleton
                    rows={1}
                    cols={2}
                    height="20px"
                    width="60%"
                    className="text-black"
                  />

                  <div className="mt-2">
                    <LoadingSkeleton
                      rows={1}
                      cols={1}
                      height="16px"
                      width="40%"
                    />
                  </div>

                  <div className="mt-3">
                    <LoadingSkeleton
                      rows={2}
                      cols={2}
                      height="16px"
                      width="80%"
                    />
                  </div>
                  <div className="mt-3">
                    <LoadingSkeleton height="44px" width="105%" xs={12} />
                  </div>
                  <div className="mt-4">
                    <LoadingSkeleton
                      rows={1}
                      cols={1}
                      height="14px"
                      width="60%"
                    />
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>
      ) : (
        (filterData?.length > 0 || openReservation?.length > 0) && (
          <>
            <p className="fw-medium fs-4 my-4">Your Next Trips</p>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-2">
              {[...openReservation, ...filterData]?.map((transaction) => (
                <div className="col" key={transaction?.id}>
                  <Card
                    bg="white"
                    text="dark"
                    className="rounded shadow-sm custom-hover"
                    onClick={() => {
                      navigate(`/user/book-details/${transaction.property_id}`);
                    }}
                  >
                    <Card.Img
                      variant="top"
                      src={transaction?.property_image_url}
                      style={{ maxHeight: "190px", minHeight: "190px" }}
                    />
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <div
                          className="fw-bold"
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {transaction?.property_name}
                        </div>
                        <Badge
                          bg="carre-gray-bg"
                          text="white"
                          className="px-2 border-1 py-1 rounded text-xs"
                        >
                          {transaction?.status}
                        </Badge>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <div
                          className="text-muted text-truncate"
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {transaction?.city_name}, {transaction?.country_name}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className="fw-medium">Booking Dates</div>
                        <div className="text-muted">
                          {moment(transaction?.date_from).format("MMM D")} -{" "}
                          {moment(transaction?.date_to).format("MMM D")}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className="fw-medium">Total Amount</div>
                        <div className="text-muted">
                          {transaction?.total_amount} VP
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <Button
                          variant="outline-carre-gray-bg"
                          size="sm"
                          className="fw-light w-100"
                          disabled={transaction?.status === "RESERVED"}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleShow(transaction);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-2">
                        <div className="text-muted fs-sm">
                          {moment(transaction?.created_at).format(
                            "MMM D, YYYY HH:mm"
                          )}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </div>
          </>
        )
      )}

      <p className="fw-medium fs-4 my-4">Vacation Booking Activity</p>
      {bookHistoryData && bookHistoryData?.length >= 0 && (
        <div>
          <div className="d-sm-block d-md-none mt-5 mb-3">
            <Button
              variant="primary"
              size="sm"
              onClick={() => setShowFilters(true)}
            >
              <IoMdFunnel /> Filters
            </Button>
          </div>
          <Offcanvas show={showFilters} onHide={() => setShowFilters(false)}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Filters</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Row>
                <Col lg={6} md={6}>
                  <Row>
                    <Col sm={12} md={12}>
                      <Form.Label>Status</Form.Label>
                      <Form.Select
                        aria-label="Status"
                        value={statusFilter}
                        onChange={(e) => setStatusFilter(e.target.value)}
                        name="statusFilter"
                      >
                        <option value="">ALL</option>
                        <option value="PENDING">Pending</option>
                        <option value="RESERVED">Reserved</option>
                        <option value="CANCELLED">Cancelled</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <span className="text-muted">Filters</span>
                <Button variant="link" onClick={clearFilters}>
                  Clear All
                </Button>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
          <div className="d-none d-md-block mt-2 mb-4">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <span className="text-muted">Filters</span>
              <Button variant="link" onClick={clearFilters}>
                Clear All
              </Button>
            </div>
            <Row>
              <Col sm={12} md={12}>
                <Form.Label>Status</Form.Label>
                <Form.Select
                  aria-label="Status"
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                  name="statusFilter"
                >
                  <option value="">ALL</option>
                  <option value="PENDING">Pending</option>
                  <option value="RESERVED">Reserved</option>
                  <option value="CANCELLED">Cancelled</option>
                </Form.Select>
              </Col>
            </Row>
          </div>
        </div>
      )}

      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-2">
        {isHistoryLoading ? (
          Array.from({ length: 6 }).map((_, index) => (
            <div className="col" key={index}>
              <Card className="rounded">
                <LoadingSkeleton rows={1} height="190px" width="100%" xs={12} />

                <Card.Body>
                  <LoadingSkeleton
                    rows={1}
                    cols={2}
                    height="20px"
                    width="60%"
                    className="text-black"
                  />

                  <div className="mt-2">
                    <LoadingSkeleton
                      rows={1}
                      cols={1}
                      height="16px"
                      width="40%"
                    />
                  </div>

                  <div className="mt-3">
                    <LoadingSkeleton
                      rows={2}
                      cols={2}
                      height="16px"
                      width="80%"
                    />
                  </div>

                  <div className="mt-4">
                    <LoadingSkeleton
                      rows={1}
                      cols={1}
                      height="14px"
                      width="60%"
                    />
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))
        ) : bookHistoryData && bookHistoryData.length > 0 ? (
          bookHistoryData.map((transaction) => (
            <div className="col" key={transaction.id}>
              <Card
                bg="white"
                text="dark"
                className="rounded shadow-sm custom-hover"
                onClick={() =>
                  navigate(`/user/book-details/${transaction.property_id}`)
                }
              >
                <Card.Img
                  style={{ maxHeight: "190px", minHeight: "190px" }}
                  variant="top"
                  src={transaction.property_image_url}
                />
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div
                      className="fw-bold"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {transaction.property_name || "N/A"}
                    </div>
                    <Badge
                      bg="carre-gray-bg"
                      text="white"
                      className="px-2 border-1 py-1 rounded text-xs"
                    >
                      {transaction.status || "N/A"}
                    </Badge>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div
                      className="text-muted text-truncate"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {transaction.street_name || "N/A"},{" "}
                      {transaction.city_name || "N/A"},{" "}
                      {transaction.country_name || "N/A"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div className="fw-medium">Booking Dates</div>
                    <div className="text-muted">
                      {transaction.date_from
                        ? `${moment(transaction.date_from).format(
                            "MMM D"
                          )} - ${moment(transaction.date_to).format("MMM D")}`
                        : "N/A"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div className="fw-medium">Total Amount</div>
                    <div className="text-muted">
                      {transaction.total_amount
                        ? `${transaction.total_amount} VP`
                        : "N/A"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <div className="text-muted fs-sm">
                      {transaction.created_at
                        ? moment(transaction.created_at).format(
                            "MMM D, YYYY HH:mm"
                          )
                        : "N/A"}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))
        ) : (
          <Alert className="text-center fs-sm p-2 fw-semibold mt-4 w-100">
            No data available.
          </Alert>
        )}
      </div>

      {/* Confirmation Modal */}
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Cancellation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel the booking for{" "}
          {selectedBooking?.property_name}?
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="secondary"
            className="fw-semibold fs-md"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            size="sm"
            variant="primary"
            className="fw-semibold fs-md"
            disabled={isLoading}
            onClick={handleCancelBooking}
          >
            {isLoading && <Spinner size={"sm"} className="mx-2" />}
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default BookingTable;
