import {
  Alert,
  Button,
  Card,
  Form,
  ListGroup,
  Placeholder,
  Row,
} from "react-bootstrap";
import { fetchWalletStats } from "../hooks/useGetWalletStats";
import { useForm } from "react-hook-form";
import moment from "moment";
import { FaInfoCircle } from "react-icons/fa";
import Col from "react-bootstrap/Col";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GiCash, GiReceiveMoney } from "react-icons/gi";
import { TbBeach, TbSettingsDollar } from "react-icons/tb";
import {
  BsFillHouseDownFill,
  BsFillHouseGearFill,
  BsFillHouseUpFill,
} from "react-icons/bs";
import Info from "./Tooltip";
import { useQuery } from "react-query";

const PaymentOverview = () => {
  const { register, handleSubmit, setValue, watch } = useForm();

  const today = moment().format("YYYY-MM-DD");
  const oneWeekAgo = moment().subtract(1, "week").format("YYYY-MM-DD");

  useEffect(() => {
    setValue("dateFrom", oneWeekAgo);
    setValue("dateTo", today);
    setSearchDateFrom(oneWeekAgo);
    setSearchDateTo(today);
  }, []);

  const [searchDateFrom, setSearchDateFrom] = useState(null);
  const [searchDateTo, setSearchDateTo] = useState(null);

  const { data: walletStats, isLoading: loading } = useQuery(
    ["walletStats", searchDateFrom, searchDateTo], // Use search dates as dependencies
    () => fetchWalletStats(searchDateFrom, searchDateTo),
    {
      enabled: !!searchDateFrom && !!searchDateTo, // Only fetch if both dates are set
      onError: (error) => {
        toast.error("Error fetching wallet stats:", error);
      },
    }
  );
  console.log(walletStats);
  const onSubmit = (formData) => {
    const { dateFrom, dateTo } = formData;

    setValue("dateFrom", dateFrom);
    setValue("dateTo", dateTo);

    // Set search dates to trigger useQuery
    setSearchDateFrom(dateFrom);
    setSearchDateTo(dateTo);
  };

  const getIcon = (transactionType) => {
    switch (transactionType) {
      case "DEPOSIT_PAYPAL":
      case "DEPOSIT_STRIPE":
      case "DEPOSIT_WIRE":
      case "DEPOSIT_COINBASE":
      case "DEPOSIT_NOWPAYMENT":
      case "DEPOSIT_COINIFY":
      case "DEPOSIT_NATIONAL_PROCESSOR":
      case "DEPOSIT_AUTHORIZE":
        return <GiReceiveMoney style={{ color: "rgba(44,138,197,0.76)" }} />;
      case "SELL_TOKEN":
        return <BsFillHouseDownFill style={{ color: "#e8705b" }} />;
      case "BUY_TOKEN":
        return <BsFillHouseUpFill style={{ color: "#71a171" }} />;

      case "SELL_TOKEN_FEE":
      case "BUY_TOKEN_FEE":
        return (
          <BsFillHouseGearFill style={{ color: "rgba(44,138,197,0.76)" }} />
        );

      case "DIVIDEND_PAYMENT":
        return <GiCash style={{ color: "rgba(44,138,197,0.76)" }} />;
      case "SELL_VACATION_POINTS":
        return <TbBeach style={{ color: "rgba(44,138,197,0.76)" }} />;
      case "BUY_VACATION_POINTS":
        return <TbBeach style={{ color: "#71a171" }} />;
      case "BUY_VACATION_POINTS_FEE":
      case "SELL_VACATION_POINTS_FEE":
        return <TbSettingsDollar style={{ color: "rgba(44,138,197,0.76)" }} />;

      case "WITHDRAW_PAYPAL":
      case "WITHDRAW_BITPAY":
      case "WITHDRAW_WIRE":
        return <GiReceiveMoney style={{ color: "#e8705b" }} />;
      default:
        return null;
    }
  };

  const cards = [
    {
      class: "mx-0 mx-md-2",
      title: `Total Rental Income`,
      style: "fs-lg text-carre-blue",

      titleInfo:
        "It indicates the monetary amount that you receive since you bought your first share.",
      cardTitleValue: ` ${walletStats?.inception_price?.toLocaleString(
        "en-US",
        {
          style: "currency",
          currency: "USD",
        }
      )}`,

      values: [
        `${walletStats?.dividend_price?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}`,
        `${walletStats?.property_qty}`,
        `${walletStats?.token_qty}`,
      ],
      infoValues: [
        "It indicates the monetary amount that you receive every day for all owned shares.",
        "It indicates the number of all properties that you have bought, with at least 1 share.",
        "It indicates the number of all owned shares",
      ],
      text: ["Daily Rental Income", "Number of Properties", "Number of Shares"],
    },
  ];

  const getRandomValueInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  return (
    <section className="py-2 py-md-4">
      {cards.map((card, index) => (
        <div key={index}>
          <Card className={` h-100 `}>
            <Card.Body className="d-flex flex-column">
              <Card.Title
                className={`${card?.style} d-flex justify-content-between fs-lg fw-medium `}
              >
                <span>
                  {card?.titleInfo !== undefined && (
                    <Info
                      classNames="fs-sm "
                      placement="top"
                      text={card?.titleInfo}
                    >
                      <span
                        style={{ marginRight: "4px" }}
                        className={`d-block text-left fw-medium fs-md  ${
                          card?.bg === "bg-carre-blue"
                            ? "text-white"
                            : "text-carre-gray-bg"
                        }`}
                      >
                        <FaInfoCircle style={{ marginBottom: 3 }} />
                      </span>
                    </Info>
                  )}
                  {card?.title}{" "}
                </span>
                {loading ? (
                  <Placeholder
                    as="span"
                    animation="wave"
                    className="w-25 text-end"
                  >
                    <Placeholder xs={getRandomValueInRange(2, 5)} />
                  </Placeholder>
                ) : (
                  <span>{card?.cardTitleValue} </span>
                )}
              </Card.Title>
              <div className="mt-auto">
                {card?.bigValue ? (
                  <div className="d-flex justify-content-center h2 fw-semibold">
                    <span>{card?.bigValue}</span>
                  </div>
                ) : (
                  card?.values?.map((value, idx) => (
                    <div
                      key={idx}
                      className={`d-flex justify-content-between fs-sm`}
                    >
                      <span className="text-truncate">
                        {card?.infoValues !== undefined && (
                          <Info
                            classNames="fs-sm"
                            placement="top"
                            text={card?.infoValues[idx]}
                          >
                            <span
                              style={{
                                marginRight: "4px",
                              }}
                              className={`d-block text-left fw-medium fs-md   ${
                                card?.bg === "bg-carre-blue"
                                  ? "text-white"
                                  : "text-carre-gray-bg"
                              }`}
                            >
                              <FaInfoCircle style={{ marginBottom: 3 }} />
                            </span>
                          </Info>
                        )}
                        {card?.text[idx]}
                      </span>
                      {loading ? (
                        <Placeholder
                          as="span"
                          animation="wave"
                          className="w-25 text-end text-carre-blue"
                        >
                          <Placeholder xs={getRandomValueInRange(2, 5)} />
                        </Placeholder>
                      ) : (
                        <span className="fs-sm text-carre-blue fw-normal text-truncate">
                          {value}
                        </span>
                      )}
                    </div>
                  ))
                )}
              </div>
            </Card.Body>
          </Card>
        </div>
      ))}
      <h5 className="card-title widget-card-title mt-4 mb-3">
        Transactions details
      </h5>
      <div className="my-2">
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-wrap justify-center"
        >
          <Row>
            <Col md={5}>
              <Form.Group
                controlId="dateFrom"
                className="w-full sm:w-auto sm:mr-2 mb-2"
              >
                <Form.Label className="mr-2">From Date</Form.Label>
                <Form.Control type="date" {...register("dateFrom")} />
              </Form.Group>
            </Col>
            <Col md={5}>
              <Form.Group
                controlId="dateTo"
                className="w-full sm:w-auto sm:mr-2 mb-2"
              >
                <Form.Label className="mr-2">To Date</Form.Label>
                <Form.Control type="date" {...register("dateTo")} />
              </Form.Group>
            </Col>
            <Col
              md={2}
              className="d-flex justify-content-center align-items-center"
            >
              <Button
                variant="primary"
                type="submit"
                className="w-100 "
                style={{ marginTop: "22px" }}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </div>

      <div>
        <div className="row justify-content-center">
          {loading ? (
            <ListGroup className="w-100">
              {[1, 2, 3, 4, 5, 6].map((item) => (
                <ListGroup.Item key={item} className="border-0 py-3">
                  <Row className="align-items-center">
                    <Col xs="auto">
                      <Placeholder animation="glow">
                        <Placeholder
                          style={{ width: "40px", height: "40px" }}
                        />
                      </Placeholder>
                    </Col>
                    <Col>
                      <Placeholder
                        animation="glow"
                        className="d-flex flex-column gap-2"
                      >
                        <Placeholder
                          xs={getRandomValueInRange(5, 8)}
                          style={{ height: "18px" }}
                        />
                        <Placeholder
                          xs={getRandomValueInRange(3, 5)}
                          style={{ height: "14px" }}
                        />
                      </Placeholder>
                    </Col>
                    <Col xs="auto">
                      <Placeholder animation="glow">
                        <Placeholder
                          xs={4}
                          style={{ width: "60px", height: "18px" }}
                        />
                      </Placeholder>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <div>
              <div>
                <div>
                  {walletStats?.details.length === 0 && (
                    <Alert className="text-center fs-sm p-2 fw-semibold">
                      No data available for the selected date range.
                    </Alert>
                  )}
                  {walletStats?.details?.map((transfer, index) => (
                    <div key={index} className="row gy-4 mt-1">
                      <div className="col-12">
                        <div className="row align-items-center fs-sm">
                          <div className="col-8">
                            <div className="d-flex align-items-center">
                              <div style={{ flexShrink: 0 }}>
                                <div className="fs-5 bg-carre-gray text-primary rounded-2 align-items-center justify-content-center me-3">
                                  <div className="p-2">
                                    {getIcon(transfer.type)}
                                  </div>
                                </div>
                              </div>
                              <div>
                                <p className="fs-md card-title fw-semibold text-dark text-start">
                                  {transfer.detail}{" "}
                                  <span className="text-muted">
                                    {transfer?.property_name}{" "}
                                    {transfer?.order_token_qty !== 0 &&
                                      transfer?.order_token_qty}
                                  </span>
                                </p>
                                <p className="text-gray m-0 fs-sm">
                                  {moment(transfer.created_at).format("l")}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-4 text-end">
                            <div>
                              <p
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "600",
                                  color: "#000",
                                  margin: "0",
                                }}
                                className="mb-1"
                              >
                                {transfer.amount?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}{" "}
                                {transfer.tax_amount > 0 && (
                                  <span
                                    className="d-none d-md-inline"
                                    style={{
                                      fontSize: "0.875rem",
                                      fontWeight: "400",
                                      color: "#6c757d",
                                      marginLeft: "0.5rem",
                                    }}
                                  >
                                    Tax:{" "}
                                    {transfer.tax_amount?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "USD",
                                      }
                                    )}
                                  </span>
                                )}
                              </p>
                              {transfer.tax_amount > 0 && (
                                <p
                                  className="d-md-none"
                                  style={{
                                    margin: "0",
                                    fontSize: "0.875rem",
                                    color: "#6c757d",
                                  }}
                                >
                                  Tax:{" "}
                                  {transfer.tax_amount?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: "USD",
                                    }
                                  )}
                                </p>
                              )}
                              <p
                                style={{
                                  margin: "0",
                                  fontSize: "0.875rem",
                                  color:
                                    transfer.amount < 0 ? "#FF0000" : "#008000",
                                }}
                              >
                                {transfer.total_balance?.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "USD",
                                  }
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default PaymentOverview;
