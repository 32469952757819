import { useQuery } from "react-query";
import api from "../api";

// export const useFetchFiles = (year) => {
//   return useQuery(["files", year], () =>
//     api()
//       .get(`user/file?year=${year}&type=TAXES`)
//       .then((res) => res.data)
//   );
// };
export const useFetchFiles = (year) => {
  return useQuery(["files", year], () => {
    return api.get(`user/file?year=${year}&type=TAXES`).then((res) => res.data);
  });
};
