import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Placeholder, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import { useMutation } from "react-query";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetAccount } from "../../hooks/useGetAccount";
import { useMakeWithdrawRequest } from "../../hooks/useMakeWithdrawRequest";
import { useSendStripePaymentId } from "../../hooks/useSendStripePaymentId";
import wire from "./../../assets/img/Cash.jpg";
import paypal from "./../../assets/img/Paypal.png";
import "./WalletCard.css"; // Import your CSS styles
// import coinbase from "./../../assets/img/coinbase.jpg"
import { useSendCoinbaseId } from "../../hooks/useSendCoinbaseId";
import { useTabContext } from "./TabContext";
import Footer from "../../components/Footer";
import PaymentOverview from "../../components/LatestAccountTransfers";

function Accounts() {
  const { setDepositModalOpen } = useTabContext();

  const { data, refetch, isLoading } = useGetAccount();
  const [searchParams, setSearchParams] = useSearchParams();
  const [requestSent, setRequestSent] = useState(false);
  const { sendStripePaymentId, result } = useSendStripePaymentId();
  const { sendCoinbaseId } = useSendCoinbaseId();
  useEffect(() => {
    refetch();
  }, [result]);
  useEffect(() => {
    const paymentId = searchParams.get("payment_id");
    if (paymentId && !requestSent) {
      setRequestSent(true);
      sendStripePaymentId(paymentId);
      refetch();
      setSearchParams((params) => {
        params.delete("payment_id");
        return params;
      });
    }
  }, [
    searchParams,
    setSearchParams,
    requestSent,
    sendStripePaymentId,
    refetch,
  ]);

  const coinbaseId = searchParams.get("coinbaseid");

  useEffect(() => {
    if (coinbaseId) {
      sendCoinbaseId(coinbaseId);
      refetch();
    }
  }, [coinbaseId, searchParams, setSearchParams]);

  const [, setAccountId] = useState("");
  const [showPaymentMethod] = useState("");
  const [amount] = useState(0);
  const [, setAmountWithFees] = useState(0);
  const [, setSendAmount] = useState(0);
  const [, setFees] = useState(0);
  // const [paypalButton, setPaypalButton] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [withdrawMethod, setWithdrawMethod] = useState("");

  useEffect(() => {
    if (showPaymentMethod === "STRIPE") {
      setShowAmountAndFee(true);
      let fee = (Number(amount) + 0.3) / (1 - 0.029);
      setFees(fee - Number(amount));
      setAmountWithFees(Number(amount) + fee - Number(amount));

      setSendAmount(Number(amount) + fee);
    } else if (showPaymentMethod === "PAYPAL") {
      setShowAmountAndFee(true);
      let fee = (Number(amount) + 0.49) / (1 - 0.0499);
      setFees(fee - Number(amount));
      setAmountWithFees(Number(amount) + fee - Number(amount));

      setSendAmount(amount + fee);
    } else if (showPaymentMethod === "WIRE") {
      setShowAmountAndFee(false);
      setFees(0);
      setAmountWithFees(amount);
      setSendAmount(amount);
    } else if (showPaymentMethod === "COINBASE") {
      setShowAmountAndFee(false);
      setFees(0);
      setAmountWithFees(amount);
      setSendAmount(amount);
    }
  }, [showPaymentMethod, amount]);

  const [, setShowAmountAndFee] = useState(false);
  const [errorMessage] = useState("");

  const { mutate } = useMutation(useMakeWithdrawRequest, {
    onSuccess: () => {
      toast.success("Successful transaction!");
      refetch().then();
      setShowWithdrawModal(false);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.error);
    },
  });
  const { data: accountData } = useGetAccount();

  const onSubmit = (data) => {
    mutate(data);
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    reset({
      type: withdrawMethod,
      from_account: accountData?.id,
    });
  }, [accountData, withdrawMethod, reset]);

  const handleWithdrawClick = () => {
    setAccountId(data?.id);
    setShowWithdrawModal(true);
  };

  const handleDepositClick = () => {
    setAccountId(data?.id);
    setDepositModalOpen(true);
  };
  const getRandomValueInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  if (false) {
    return (
      <Container>
        <Card className="mb-4 p-4">
          <Row>
            <Col xs={12} md={8}>
              <Placeholder as="h2" animation="wave">
                <Placeholder xs={6} />
              </Placeholder>
              <Placeholder animation="wave">
                <Placeholder xs={4} /> <Placeholder xs={2} />
              </Placeholder>
              <hr />
              <Placeholder animation="wave">
                <Placeholder xs={5} /> <Placeholder xs={3} />
              </Placeholder>
            </Col>

            {/* Right Side: Deposit and Withdraw Buttons */}
            <Col
              xs={12}
              md={4}
              className="d-flex flex-column align-items-end mt-3 mt-md-0"
            >
              <Placeholder.Button
                variant="primary"
                xs={6}
                className="mb-2 h-50 w-100 p-2"
              />
              <Placeholder.Button
                variant="outline-primary"
                xs={6}
                className="h-50 w-100 "
              />
            </Col>
          </Row>
        </Card>
        <Card>
          <Card.Body>
            <Row className="d-flex align-items-center mb-2">
              <Col xs={9}>
                <Placeholder
                  as={Card.Title}
                  animation="glow"
                  className="gap-2 d-flex"
                >
                  <Placeholder xs={1} />
                  <Placeholder xs={7} />
                </Placeholder>
              </Col>
              <Col xs={3}>
                <Placeholder
                  as={Card.Title}
                  animation="glow"
                  className="gap-2 d-flex"
                >
                  <Placeholder xs={12} />
                </Placeholder>
              </Col>
            </Row>

            {[1, 2, 3].map((item, index) => (
              <Row className="d-flex align-items-center" key={index}>
                <Col xs={9}>
                  <Placeholder
                    as={Card.Title}
                    animation="glow"
                    className="gap-2 d-flex"
                  >
                    <Placeholder xs={1} />
                    <Placeholder xs={getRandomValueInRange(6, 9)} />
                  </Placeholder>
                </Col>
                <Col xs={3}>
                  <Placeholder
                    as={Card.Title}
                    animation="glow"
                    className="gap-2 d-flex justify-content-end"
                  >
                    <Placeholder xs={getRandomValueInRange(7, 12)} />
                  </Placeholder>
                </Col>
              </Row>
            ))}
          </Card.Body>
        </Card>

        <Placeholder as="h2" animation="glow" className="mt-4">
          <Placeholder xs={4} />
        </Placeholder>
      </Container>
    );
  }

  return (
    <>
      <Container className="mt-5 mt-sm-0">
        <Card className="mt-5">
          <Card.Body>
            <Row>
              <Col sm={12} md={8} className="mb-2 mb-md-0">
                <div className="fw-medium fs-3">
                  {isLoading ? (
                    <Placeholder as="h4" animation="wave" className="my-0">
                      <Placeholder xs={6} />
                    </Placeholder>
                  ) : (
                    <span>
                      {data?.total_balance.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </span>
                  )}
                </div>

                {isLoading ? (
                  <Placeholder
                    as="h6"
                    animation="wave"
                    className="d-flex text-muted fw-normal my-0"
                  >
                    <Placeholder xs={1} /> Reserved
                  </Placeholder>
                ) : (
                  <span className="pt-2 text-muted">
                    {data?.reserved_balance.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}{" "}
                    Reserved
                  </span>
                )}
                <div className="border-2 border-top w-100 text-muted">
                  {isLoading ? (
                    <Placeholder
                      as="h6"
                      animation="wave"
                      className="d-flex text-muted fw-normal "
                    >
                      <Placeholder xs={1} /> Available
                    </Placeholder>
                  ) : (
                    <span className="pt-2">
                      {data?.balance.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}{" "}
                      Available
                    </span>
                  )}
                </div>
              </Col>
              <Col className="mt-2">
                <Button
                  size="sm"
                  onClick={handleDepositClick}
                  variant="carre-blue"
                  className="mr-2 w-100 mb-3"
                >
                  <GiPayMoney className="mx-2" size={20} /> Deposit
                </Button>
                <Button
                  size="sm"
                  className="w-100 bg-white text-carre-blue"
                  onClick={handleWithdrawClick}
                >
                  <GiReceiveMoney className="mx-2" size={20} /> Withdraw
                </Button>
                {/*<Button*/}
                {/*  size="sm"*/}
                {/*  // onClick={() => navigate("SELL/order")}*/}
                {/*  className="w-100 bg-white text-carre-blue"*/}
                {/*>*/}
                {/*  Sell*/}
                {/*</Button>*/}
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <PaymentOverview />
        {/*<PaymentMethodModal />*/}
        <Modal
          show={showWithdrawModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => setShowWithdrawModal(false)}
        >
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
              <Row xs={1} md={3} className="justify-content-between mt-2 gx-4">
                <Col md={6}>
                  <Card
                    className={`mb-3 ${
                      withdrawMethod === "PAYPAL"
                        ? "border-carre-blue"
                        : "border"
                    }`}
                    onClick={() => setWithdrawMethod("PAYPAL")}
                  >
                    <img
                      src={paypal}
                      alt="PayPal"
                      className="img-fluid  "
                      style={{
                        objectFit: "scale-down",
                        cursor: "pointer",
                        maxHeight: "110px",
                      }}
                    />
                    <Card.Text className="text-center fw-bold">
                      PayPal
                    </Card.Text>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card
                    className={`mb-3  ${
                      withdrawMethod === "WIRE" ? "border-carre-blue" : "border"
                    }`}
                    onClick={() => setWithdrawMethod("WIRE")}
                  >
                    <img
                      src={wire}
                      alt="wire"
                      className="img-fluid  "
                      style={{
                        objectFit: "scale-down",
                        cursor: "pointer",
                        maxHeight: "110px",
                      }}
                    />
                    <Card.Text className="text-center fw-bold">
                      Wire Transfer
                    </Card.Text>
                  </Card>
                </Col>
              </Row>

              <Form.Group>
                <label className="text-muted h6">Payment method</label>
                <Row className="justify-content-between ">
                  <Form.Group>
                    <Form.Select
                      className={
                        errors.from_account
                          ? "text-carre-blue form-control border-carre-red mb-2"
                          : " form-control false"
                      }
                      {...register("from_account", {
                        required: false,
                      })}
                      disabled
                    >
                      <option key={1} value={accountData?.id}>
                        Carré Account ${accountData?.balance?.toFixed(2)}
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Row>
              </Form.Group>
              <Row className="justify-content-between ">
                <Form.Group>
                  <Form.Label>Amount</Form.Label>
                  <input
                    type="number"
                    step="0.0000001"
                    className={
                      errors.amount
                        ? "text-carre-blue form-control border-carre-red mb-2"
                        : "form-control false"
                    }
                    {...register("amount", {
                      required: true,
                      valueAsNumber: true,
                      min: 0.01,
                    })}
                    max={accountData?.balance}
                    onChange={(event) => {
                      const input = parseFloat(event.target.value); // Parse the input value to a number

                      if (input > accountData?.balance) {
                        toast.info(
                          "Quantity above limit, setting custom amount to maximum quantity"
                        );
                        setValue("amount", accountData?.balance.toFixed(2));
                      } else {
                        setValue("amount", input);
                      }
                    }}
                    placeholder="Enter amount"
                  ></input>
                </Form.Group>
              </Row>
              {withdrawMethod === "PAYPAL" && (
                <>
                  <Form.Group></Form.Group>
                  <Form.Group controlId="si-email" className="mb-4">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      {...register("withdraw_to")}
                      type="string"
                      placeholder="Enter your paypal email or username"
                      required
                    />
                  </Form.Group>
                </>
              )}
              {withdrawMethod === "WIRE" && (
                <>
                  <Form.Group>
                    <Row className="justify-content-between ">
                      <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          className={
                            errors.recipient_name
                              ? "text-carre-blue form-control border-carre-red mb-2"
                              : " form-control false"
                          }
                          {...register("recipient_name", {
                            required: true,
                          })}
                          placeholder="Enter your name"
                        ></Form.Control>
                      </Form.Group>
                    </Row>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Account number</Form.Label>
                    <Form.Control
                      type="text"
                      className={
                        errors.recipient_account_number
                          ? "text-carre-blue form-control border-carre-red mb-2"
                          : " form-control false"
                      }
                      {...register("recipient_account_number", {
                        required: true,
                        // valueAsNumber: true,
                      })}
                      placeholder="Enter your account number"
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Iban</Form.Label>
                    <Form.Control
                      type="text"
                      className={
                        errors.iban
                          ? "text-carre-blue form-control border-carre-red mb-2"
                          : " form-control false"
                      }
                      {...register("iban", {
                        required: true,
                        // valueAsNumber: true,
                      })}
                      placeholder="Enter your iban"
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Bank Name</Form.Label>
                    <Form.Control
                      type="text"
                      className={
                        errors.bank_name
                          ? "text-carre-blue form-control border-carre-red mb-2"
                          : " form-control false"
                      }
                      {...register("bank_name", {
                        required: true,
                        // valueAsNumber: true,
                      })}
                      placeholder="Enter your bank name"
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Bank Address</Form.Label>
                    <Form.Control
                      type="text"
                      className={
                        errors.bank_address
                          ? "text-carre-blue form-control border-carre-red mb-2"
                          : " form-control false"
                      }
                      {...register("bank_address", {
                        required: true,
                        // valueAsNumber: true,
                      })}
                      placeholder="Enter your bank address"
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Bank SWIFT</Form.Label>
                    <Form.Control
                      type="text"
                      className={
                        errors.from_account
                          ? "text-carre-blue form-control border-carre-red mb-2"
                          : " form-control false"
                      }
                      {...register("recipient_bank_swift", {
                        required: true,
                      })}
                      placeholder="Enter your SWIFT"
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId="formCurrency" className="mt-1">
                    <Form.Label>
                      Currency{" "}
                      <span className="text-muted fs-sm">
                        Receiving currency
                      </span>
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="USD"
                        value="USD"
                        name="currency"
                        {...register("currency")}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="EUR"
                        value="EUR"
                        name="currency"
                        {...register("currency")}
                      />
                    </div>
                    {errors.currency && (
                      <Form.Text className="text-danger">
                        Currency is required
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      type="text"
                      className={
                        errors.country
                          ? "text-carre-blue form-control border-carre-red mb-2"
                          : " form-control false"
                      }
                      {...register("country", {
                        required: true,
                        // valueAsNumber: true,
                      })}
                      placeholder="Enter your country"
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      className={
                        errors.city
                          ? "text-carre-blue form-control border-carre-red mb-2"
                          : " form-control false"
                      }
                      {...register("city", {
                        required: true,
                        // valueAsNumber: true,
                      })}
                      placeholder="Enter your city"
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId="si-email" className="mb-4">
                    <Form.Label>Mail Address</Form.Label>
                    <Form.Control
                      {...register("recipient_mailing_address")}
                      type="string"
                      placeholder="Enter your mailing address"
                      required
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="exampleForm.ControlTextarea1"
                    className="mb-4"
                  >
                    <Form.Label>Note</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      {...register("recipient_mailing_address")}
                      placeholder="Note"
                    />
                  </Form.Group>
                </>
              )}

              {errorMessage && (
                <p className=" text-carre-red">{errorMessage}</p>
              )}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button
                variant="secondary w-25"
                onClick={() => setShowWithdrawModal(false)}
                size="sm"
              >
                Close
              </Button>
              <Button
                type={"submit"}
                // onClick={() => handleDepositeModal()}
                size="sm"
                variant="carre-blue text-white w-50"
                disabled={isLoading}
              >
                {!isLoading ? "Withdraw" : "Please wait"}
                {/* {withdrawMethod !== "WIRE" && (!withdrawAmount || withdrawAmount === 0)
              ? "Amount Required"
              : "Process"} */}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <style>
          {`
  @media (max-width: 767.98px) {
    .object-fit-lg {
      object-fit: scale-down;
    }
  }
  `}
        </style>
      </Container>
      <Footer />
    </>
  );
}

export default Accounts;
