import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetAccount } from "../../hooks/useGetAccount";
import { Button, Card, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast, ToastContainer } from "react-toastify";
import Col from "react-bootstrap/Col";
import GoBackButton from "../../components/GoBackButton";
import { useSellPropertyToken } from "../../hooks/useSellPropertyToken";
import { useGetPropertyForBuySellRequest } from "../../hooks/useGetPropertyForBuySellRequest";
import { useGetAppConfig } from "../../hooks/useGetAppConfig";
import { useGetMarketStageById } from "../../hooks/useGetMArketStageById";
import ConfirmModal from "./ConfirmModal";
import Tooltip from "../../components/Tooltip";
import { MdInfo } from "react-icons/md";
import { useGetOrderDetailsById } from "../../hooks/useGetOrderDetails";
import TransferModal from "./OrderDataInfo";
import moment from "moment/moment";
import { useGetOrderBookById } from "../../hooks/useGetOrderBookById";
import OrderBook from "../../components/OrderBook";
import { useCalculateTaxesMutation } from "../../hooks/useCalculateTaxes";

function BuySellScreen() {
  const params = useParams();
  const id = params.id;
  const { data: accountData, refetch } = useGetAccount();
  const [tokenRange, setTokenRange] = useState(1);
  const { data: orderDetails } = useGetOrderDetailsById(id);

  const orderType = "SELL";
  const { data, refetch: refetchProperty } = useGetPropertyForBuySellRequest(
    id,
    orderType
  );
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [transferData, setTransferData] = useState({});
  const handleCloseModal = () => {
    setShowTransferModal(false);
  };
  const { data: marketStage, refetch: marketStageRefetch } =
    useGetMarketStageById(data?.property?.market_stage);
  const { data: orders, refetch: refetchOrders } = useGetOrderBookById(id);

  const highestBid = orders
    ?.filter((order) => order.type === "BUY")
    .reduce(
      (highest, order) => {
        return order.token_price > highest.token_price ? order : highest;
      },
      { token_price: -Infinity }
    ) || { token_price: "No data" };

  const lowestAsk = orders
    ?.filter((order) => order.type === "SELL")
    .reduce(
      (lowest, order) => {
        return order.token_price < lowest.token_price ? order : lowest;
      },
      { token_price: Infinity }
    ) || { token_price: "No data" };

  const [tokenPrice, setTokenPrice] = useState();

  const buyOrders = orders?.filter((order) => order.type === "BUY");
  const highestBuyOrder = buyOrders?.find(
    (order) =>
      order.token_price === Math.max(...buyOrders.map((o) => o.token_price))
  );

  useEffect(() => {
    if (data?.property?.market_stage_name === "Upcoming") {
      setTokenPrice(data?.property?.token_price_up_coming);
    } else if (data?.property?.market_stage_name === "Secondary") {
      setTokenPrice(highestBuyOrder?.token_price || orderDetails?.token_price);
    } else {
      setTokenPrice(data?.property?.token_price);
    }
  }, [data, orderDetails]);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);

  // Handle opening and closing the ConfirmModal
  const openConfirmModal = () => {
    setConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  const handleSellRequest = () => {
    handleSubmit(onSubmit)();
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      account_id: accountData?.id,
      token_qty: 1,
      type: "SELL",
    },
  });

  useEffect(() => {
    reset({
      account_id: accountData?.id,
      token_qty: 1,
      id: id,
      token_price: tokenPrice,
      type: "SELL",
    });
  }, [accountData, reset]);

  const { data: fees } = useGetAppConfig();
  useEffect(() => {
    marketStageRefetch().then();
  }, [data]);

  function calculatePrice(amount) {
    let price = 0;
    if (marketStage?.name === "Primary") {
      price = (fees?.primary_sell_fee / 100) * amount;
    } else if (marketStage?.name === "Secondary") {
      price = (fees?.secondary_sell_fee / 100) * amount;
    }

    return price;
  }

  useEffect(() => {
    refetch();
    refetchOrders();
    refetchProperty().then();
  }, [id]);
  const notifySuccess = () =>
    toast.success("Share Sell Request Sent Successfully");

  const { mutate, isLoading } = useMutation(useSellPropertyToken, {
    onSuccess: (data) => {
      notifySuccess();
      closeConfirmModal();
      setShowTransferModal(true);
      setTransferData(data);
      refetchProperty();
      refetch().then();
    },
    onError: (error) => {
      notifyError(error?.response.data.error);
    },
  });
  const notifyError = (error) => toast.error(error);
  useEffect(() => {
    setValue("token_qty", parseInt(tokenRange));
    setValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenRange]);
  useEffect(() => {
    if (id) {
      setValue("id", id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenRange]);

  const onSubmit = (data) => {
    mutate({
      ...data,
      token_price: Number(tokenPrice),

      end_period: data.end_period === "until_completed" ? "" : data.end_period,
    });
  };

  const [activeMaxButton, setActiveMaxButton] = useState(false);

  useEffect(() => {
    if (Number(tokenRange) === data?.property.owned_token) {
      setActiveMaxButton(true);
    } else {
      setActiveMaxButton(false);
    }
  }, [tokenRange]);

  const { mutate: calculateTaxes, data: taxesData } =
    useCalculateTaxesMutation();
  useEffect(() => {
    const requestData = {
      type: "SELL_ORDER",
      property_id: id,
      account_id: accountData?.id,
      token_price: tokenPrice,
      total_token: tokenRange,
    };

    calculateTaxes(requestData);
  }, [accountData, tokenRange, tokenPrice]);
  const secondaryData = (
    <div>
      <div className="fs-lg fw-semibold">
        Selling {tokenRange} shares of {data?.property?.property_name} property
        at{" "}
        {tokenPrice?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}{" "}
        per share
        {data?.property?.market_stage_name === "Secondary" && " or lower"}
      </div>

      <div className="fw-semibold my-2 w-100 fs-md d-flex justify-content-between">
        <div className="flex-grow-0">Account:</div>
        <div className="flex-grow-1 text-end">
          Carré <span>($ {accountData?.balance.toFixed(2)})</span>
        </div>
      </div>
      <div className="fw-semibold my-2 w-100 fs-md d-flex justify-content-between">
        <div className="flex-grow-0">Fees:</div>
        <div className="flex-grow-1 text-end">
          {calculatePrice(tokenRange * tokenPrice)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </div>
      </div>
      <div className="fw-semibold my-2 w-100 fs-md d-flex justify-content-between">
        <div className="flex-grow-0">Estimated Tax:</div>
        <div className="flex-grow-1 text-end">
          {taxesData?.tax?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </div>
      </div>
      <div className="fw-semibold my-2 w-100 fs-md d-flex justify-content-between">
        <div className="flex-grow-0">Estimated Profit:</div>
        <div className="flex-grow-1 text-end">
          {taxesData?.profit?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </div>
      </div>
      <div className="fw-semibold my-2 w-100 fs-md d-flex justify-content-between">
        <div className="flex-grow-0">
          {data?.property?.market_stage_name === "Secondary" && "Estimated "}
          Amount Received:
        </div>
        <div className="flex-grow-1 text-end">
          {(
            tokenRange * tokenPrice -
            calculatePrice(tokenRange * tokenPrice)
          )?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </div>
      </div>

      {data?.property?.market_stage_name === "Secondary" && (
        <>
          <div className="fw-semibold my-2 w-100 fs-md d-flex justify-content-between">
            <div className="flex-grow-0">Ask:</div>
            <div className="flex-grow-1 text-end">
              {lowestAsk.token_price !== Infinity
                ? `${
                    lowestAsk.token_qty
                  } X ${lowestAsk.token_price.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}`
                : "No data"}
            </div>
          </div>
          <div className="fw-semibold my-2 w-100 fs-md d-flex justify-content-between">
            <div className="flex-grow-0">Bid:</div>
            <div className="flex-grow-1 text-end">
              {highestBid.token_price !== -Infinity
                ? `${
                    highestBid.token_qty
                  } X ${highestBid.token_price.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}`
                : "No data"}
            </div>
          </div>

          <div className="fw-semibold my-2 w-100 fs-md d-flex justify-content-between">
            <div className="flex-grow-0">Last:</div>
            <div className="flex-grow-1 text-end">
              {data?.property?.token_price?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </div>
          </div>
        </>
      )}

      <div className="mx-1 mt-4">As of {moment().format("MMMM Do YYYY")}</div>
    </div>
  );
  return (
    <div>
      <Container className="container-sm">
        <GoBackButton />
        <Row className="justify-content-center mb-5">
          <Row>
            <Col md={7}>
              <div style={{ height: "100%", overflow: "hidden" }}>
                <img
                  src={data?.property?.property_image?.image_url}
                  alt="Property"
                  className="w-100 rounded-2 h-100"
                  style={{
                    objectFit: "fill",
                    maxHeight: "350px",
                  }}
                />
              </div>
            </Col>
            <Col md={5}>
              <Card className="h-100">
                <Card.Body>
                  <Card.Title>{data?.property?.property_name}</Card.Title>
                  <Card.Text>
                    <span className="text-muted">
                      {data?.property?.address.street_name}
                    </span>
                    <span className="text-muted">
                      {data?.property?.address.city_name}
                      ,&nbsp;
                      {data?.property?.address.country_state}{" "}
                      {data?.property?.address.country_name}
                      &nbsp;
                      {data?.property?.address.zipcode}
                    </span>
                    {data?.property?.owned_token > 0 && (
                      <Row className="mt-4">
                        <Row>
                          <Col>
                            <div className="d-inline-block">
                              <h6 className="text-muted">
                                Current Number Of Shares
                              </h6>
                              <h6 className="text-carre-blue">
                                {data?.property?.owned_token}
                              </h6>
                            </div>
                          </Col>
                          <Col>
                            <div className="d-inline-block">
                              <h6 className="text-muted">
                                Current Position Value
                              </h6>
                              <h6 className="text-carre-blue">
                                {(
                                  data?.property?.owned_token * 100
                                ).toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                              </h6>
                            </div>
                          </Col>
                        </Row>
                      </Row>
                    )}
                  </Card.Text>
                </Card.Body>
                {/*<Card.Footer>*/}
                {/*  <Card.Link href="#">Preview property </Card.Link>*/}
                {/*</Card.Footer>*/}
              </Card>
            </Col>
          </Row>
          {data?.property?.market_stage_name === "Secondary" && (
            <OrderBook id={id} marketStage={"Secondary"} onlyOrder={true} />
          )}
          <Row className="justify-content-center mt-2">
            <Row className="justify-content-evenly">
              {/*<div style={buttonContainerStyle}>*/}
              <div className=" col-md-7 mb-2 ">
                <Form.Group>
                  <Form.Label className="text-muted h6">
                    Sell quantity
                  </Form.Label>
                  <Form.Control
                    className="me-auto"
                    placeholder="Amount"
                    onWheel={(e) => e.target.blur()}
                    type="number"
                    id="tokenRangeInput"
                    name="tokenRangeInput"
                    min={0}
                    max={data?.calculation?.token_available_to_sell}
                    value={tokenRange}
                    onChange={(event) => {
                      const input = event.target.value;
                      const wholeNumber = /^\d*$/.test(input);

                      if (wholeNumber) {
                        if (
                          input > data?.calculation?.token_available_to_sell
                        ) {
                          toast.info(
                            "Quantity above limit, setting custom amount to maximum quantity"
                          );
                          setTokenRange(
                            data?.calculation?.token_available_to_sell
                          );
                        } else {
                          setTokenRange(input);
                        }
                      }
                    }}
                  />
                </Form.Group>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center col-md-1">
                <label className="text-muted text-center">or</label>
              </div>
              <div className=" col-md-4 mb-2">
                <Button
                  className={"small w-100 h-100"}
                  onClick={() =>
                    setTokenRange(data?.calculation?.token_available_to_sell)
                  }
                  variant="outline-carre-blue"
                  active={activeMaxButton}
                >
                  <div>
                    <div className="mb-2 fs-sm">Max amount</div>
                    <div className="fs-sm">
                      {data?.calculation?.token_available_to_sell} shares
                    </div>
                  </div>
                </Button>
              </div>
            </Row>
            <Row>
              <Form.Group>
                <label className="text-muted h6">Payment method</label>
                <Row className="justify-content-between ">
                  <Form.Group>
                    <Form.Select
                      className={
                        errors.account_id
                          ? "text-carre-purple form-control border-carre-red mb-2"
                          : " form-control false"
                      }
                      {...register("account_id", {
                        required: true,
                      })}
                      disabled
                    >
                      <option key={1} value={accountData?.id}>
                        Carré Account ${accountData?.balance?.toFixed(2)}
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Row>
                {errors.account_id && (
                  <p className="text-carre-red">This field is required</p>
                )}
              </Form.Group>
            </Row>
            {/*<Row>*/}
            {/*  <Form.Group>*/}
            {/*    <Form.Label className="text-muted h6">*/}
            {/*      Price per token*/}
            {/*    </Form.Label>*/}
            {/*    <Form.Control*/}
            {/*      className="me-auto"*/}
            {/*      placeholder="Amount"*/}
            {/*      value={data?.property?.token_price?.toLocaleString("en-US", {*/}
            {/*        style: "currency",*/}
            {/*        currency: "USD",*/}
            {/*      })}*/}
            {/*      readOnly={true}*/}
            {/*    />*/}
            {/*  </Form.Group>*/}
            {/*</Row>*/}
            <Row>
              <Form.Group>
                <Form.Label className="text-muted h6">
                  Price per share{" "}
                  <Tooltip
                    children={
                      <>
                        <MdInfo />
                      </>
                    }
                    text={
                      "When property is in secondary market you can chose the price"
                    }
                  />
                </Form.Label>
                <Form.Control
                  className="me-auto"
                  placeholder="Amount"
                  type="number"
                  onChange={(event) => setTokenPrice(event.target.value)}
                  defaultValue={tokenPrice}
                  readOnly={data?.property?.market_stage_name !== "Secondary"}
                  disabled={data?.property?.market_stage_name !== "Secondary"}
                />
              </Form.Group>
            </Row>
            {data?.property?.market_stage_name === "Secondary" && (
              <Row>
                <Form.Group>
                  <Form.Label className="text-muted h6">
                    Offer valid until
                    <Tooltip
                      children={
                        <>
                          <MdInfo />
                        </>
                      }
                      text={"Until when do you wna tyour offert to be valide"}
                    />
                  </Form.Label>
                  <Form.Select
                    className={
                      errors.end_period
                        ? "text-carre-blue form-control border-carre-red mb-2"
                        : " form-control false"
                    }
                    {...register("end_period", {
                      required: true,
                    })}
                  >
                    <option key={1} value={"until_completed"}>
                      Until Completed
                    </option>
                    <option key={2} value={"END_OF_DAY"}>
                      End of day
                    </option>
                  </Form.Select>
                </Form.Group>
              </Row>
            )}
            <Row>
              <Form.Group>
                <Form.Label className="text-muted h6">Fees</Form.Label>
                <Form.Control
                  className="me-auto"
                  placeholder="Amount"
                  value={calculatePrice(
                    tokenRange * tokenPrice
                  )?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                  readOnly={true}
                  disabled={true}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group>
                <Form.Label className="text-muted h6">Estimated Tax</Form.Label>
                <Form.Control
                  className="me-auto"
                  placeholder="Tax"
                  value={taxesData?.tax?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                  readOnly={true}
                  disabled={true}
                />
              </Form.Group>
            </Row>
            <Row className="justify-content-between">
              <label className="h6 text-muted"> Total Amount</label>
              <Col md={8}>
                <Form.Group>
                  <Form.Label className="text-muted h6" visuallyHidden={true}>
                    Total Amount
                  </Form.Label>
                  <Form.Control
                    className="me-auto mb-2 "
                    placeholder="Amount"
                    value={(
                      tokenRange * tokenPrice -
                      calculatePrice(tokenRange * tokenPrice)
                    )?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                    readOnly={true}
                    disabled={true}
                  />
                </Form.Group>
              </Col>

              <Col md={4} className="small mb-5">
                <Button
                  onClick={openConfirmModal}
                  type={"submit"}
                  size="md"
                  variant="carre-blue text-white w-100"
                  disabled={isLoading}
                >
                  {!isLoading ? "Sell shares" : "Please wait"}
                </Button>
              </Col>
            </Row>
          </Row>
        </Row>
      </Container>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={closeConfirmModal}
        onConfirm={handleSellRequest}
        disableButton={isLoading}
        confirmButtonText={isLoading ? "Please Wait" : "Confirm"}
        title={`${
          data?.property?.market_stage_name === "Upcoming"
            ? "Reserve"
            : data?.property?.market_stage_name === "Secondary"
            ? "Order"
            : "Selling"
        } Review`}
      >
        <div className="mt-2">{secondaryData}</div>
      </ConfirmModal>
      <TransferModal
        property={data?.property}
        transferData={transferData}
        show={showTransferModal}
        isSell={true}
        isSecondary={data?.property?.market_stage_name === "Secondary"}
        handleClose={handleCloseModal}
      />
    </div>
  );
}

export default BuySellScreen;
